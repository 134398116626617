export default function repositoriesList() {
  return [
    {
      title: 'Pujan77/portfolio-personal-site',
      description: 'Portfolio app to show my skills and experience.',
      cover: 'https://imgur.com/i2JS2w1.png',
      language: 'Javascript',
      techStack: ['ReactJs', 'chakraUi'],
      url: 'https://github.com/Pujan77/portfolio-personal-site',
      live: 'https://portfolio-personal-site-pujan77.vercel.app/',
      stargazers_count: 1,
      forks_count: 0,
    },
    {
      title: 'Pujan77/snrp-frontend',
      description: 'A frontend of a full stack application built using MERN.',
      cover: 'https://imgur.com/JdwnWgS.png',
      language: 'Javascript',
      techStack: ['react', 'javascript', 'bootstrap'],
      url: 'https://github.com/Pujan77/snrp-frontend',
      live: 'https://snrp-frontend-git-main-pujan77.vercel.app/',
      stargazers_count: 1,
      forks_count: 0,
    },
    {
      title: 'Pujan77/react-landing-business',
      description: 'This is a landing page for SAAS business.',
      cover: 'https://imgur.com/BRPhOQi.png',
      language: 'Javascript',
      techStack: ['react', 'javascript', 'bootstrap'],
      url: 'https://github.com/Pujan77/react-landing-business',
      live: 'http://react-landing-business.vercel.app/',
      stargazers_count: 1,
      forks_count: 0,
    },
    {
      title: 'Pujan77/frontend-woda-app',
      description:
        'A municipality office website that has various features. Created as a help to a friend.',
      cover: 'https://imgur.com/yldqIjg.png',
      language: 'Javascript',
      techStack: ['react', 'javascript', 'chakraUi'],
      url: 'https://github.com/Pujan77/frontend-woda-app/',
      live: 'https://frontend-woda.vercel.app/',
      stargazers_count: 1,
      forks_count: 0,
    },
    {
      title: 'Pujan77/movie-app-react',
      description:
        'An IMDB movie catalog app. Further enhancement left to get movie details.',
      cover: 'https://imgur.com/niSyS3K.png',
      language: 'Javascript',
      techStack: ['react', 'javascript', 'CSS'],
      url: 'https://github.com/Pujan77/movie-app-react',
      live: 'https://movie-app-react-rust.vercel.app/',
      stargazers_count: 1,
      forks_count: 0,
    },
  ];
}
